<script>
    import dayjs from 'dayjs';

    import TourFormMobile from '$src/views/Tour/TourFormMobile.svelte';

    export let isTravelAloneAuthorized;
    export let isClosedSales;
    export let code;
    export let maxAgeAllowed;
    export let minAgeAllowed;
    export let bookingType;
    export let tourType;
    export let minPrice;
    export let originalPrice;
    export let rebatePercentage;
    export let token;
    export let csrfToken;
    export let isBookable;
    export let bookableDates;
    export let allTourUrl;
    export let childrenAges;
    export let bookingFormAction;
    export let bookingRequestAction;

    // FIT only
    export let closedSales;
    let closedSalesFormatted;

    $: {
        closedSalesFormatted = JSON.parse(closedSales);
        closedSalesFormatted = closedSalesFormatted.map((closedSale) => {
            return {
                startDate: dayjs(closedSale.startDate),
                endDate: dayjs(closedSale.endDate),
            };
        });
    }
</script>

<TourFormMobile
    {isTravelAloneAuthorized}
    {isClosedSales}
    {code}
    {maxAgeAllowed}
    {minAgeAllowed}
    {bookingType}
    {tourType}
    {minPrice}
    {originalPrice}
    {rebatePercentage}
    {token}
    {csrfToken}
    {isBookable}
    {bookableDates}
    {allTourUrl}
    {childrenAges}
    {bookingFormAction}
    {bookingRequestAction}
    closedSales={closedSalesFormatted}
/>
